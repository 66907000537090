import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PTZTable from 'components/Web_User_Interface/720p_Series/System/PTZ/ptzTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "System Menu // PTZ",
  "path": "/Web_User_Interface/720p_Series/System/PTZ/",
  "dateChanged": "2017-12-10",
  "author": "Mike Polinowski",
  "excerpt": "Deactivate your camera´s calibration run at startup (will also deactivate the saved positions). Let your camera go to a saved position after a restart or limit the range of the pan & tilt motion for privacy concerns.",
  "image": "./WebUI_720p_SearchThumb_System_PTZ.png",
  "social": "/images/Search/WebUI_720p_SearchThumb_System_PTZ.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='System Menu // PTZ' dateChanged='2017-12-10' author='Mike Polinowski' tag='INSTAR IP Camera' description='Deactivate your camera´s calibration run at startup (will also deactivate the saved positions). Let your camera go to a saved position after a restart or limit the range of the pan & tilt motion for privacy concerns.' image='/images/Search/WebUI_720p_SearchThumb_System_PTZ.png' twitter='/images/Search/WebUI_720p_SearchThumb_System_PTZ.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/System/PTZ/' locationFR='/fr/Web_User_Interface/720p_Series/System/PTZ/' crumbLabel="PTZ" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "system-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#system-menu",
        "aria-label": "system menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`System Menu`}</h2>
    <h3 {...{
      "id": "ptz",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ptz",
        "aria-label": "ptz permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PTZ`}</h3>
    <p><strong parentName="p">{`Pan & Tilt Function`}</strong>{`
`}<em parentName="p">{`(only in Pan, Tilt & Zoom Cameras)`}</em></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`Deactivate your camera´s calibration run at start-up (will also deactivate the saved positions). Let your camera go to a saved position after a restart or limit the range of the pan & tilt motion for privacy concerns.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/250c8266d01e836848b2acf2925b8221/6bbf7/WebUI-System_PTZ.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAC8klEQVQ4y22TTXPaRhjH+WBtPkbqa/MdfPInaO6awExcx4CAUXqAsWFGBjEYYcRgsEtDMC/Svmil1Wq1kqA5NMcOkluB499hDzvz2//u8zybq9cbb385+ennN29PTvTB4K8vXyaTyWKxWCWsD1itVgAAXdff/fpOkqTz899zzWYz/yH/2/v3H8/P1+u1aVnz+ZxSGkWReI04jhVFub6+1vV+rlqtyhW5Xq+3222EMIRwMp3O51+Xy+Vi8cQ5Dw7wfZ8x1u/3DcOYzWY5WS5LklSt1lRVhRA6Dlkul57npTnRMUII27Y1TdN1/eHhIXd5eSlJkqIorVYLAOC6LkIQAIAQDsNQCBEeIITwfT+Tu5qmqup9AkLIddz1eg0AgPsTAIQwDMP4P6IoYoxlshBiG2+///N9t9s5joMRWj49bTabffFM80W1giAIwzCTiW1DAL79/W273TqOk7RnE2+3/0e9eLPrupkMLGs2+5NzzhhzHAdYJoQgiqLwNYIgoJRmcqPeOD09PTs7azQaQRCsVkvTNNOz/ITDVjHGEELdbvdZ9ihNomIhQkIIQsg0TYyQZVkAAGzbL94shMiS//j8uZjQbLYwRtSlaWa6cs7jY6IoyuSLi4tCodBsNlutFoQQIWSZFsYYWPtshNDhtTnnR8mlUvHTp4tKpXJ1dQUhZIxxzv1kjaJot9u9iD2Sb3u9NLZ3e4sxJoSkn2ljmr7vv9qtTDYMo9PpaJo2Go0IIXRfP4gTfhxv3+eEkEy+u7u7ublpt9vD4dC2bcYYxti2bYz3Pwwk45macRzvRxDjrFXj8VjTtF6vNxqNbNv2PA9jlLg4CIIfr300noZhqKra7WqGYWCMPc9DCGGEMLbTj5kWmfs8iuIgCAghnU7nWX58fDQMYzKZ3N/fU0rDMKR032rGGKUeSaCUuq6b7LhCiH6//ywPBgNN07TuvmDj8bhWq5VKpXKCLMsf8vl8oVCpVGRZLpfLxWJRUZRarTYcDqfT6b8oAxyO5sNqNAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/250c8266d01e836848b2acf2925b8221/e4706/WebUI-System_PTZ.avif 230w", "/en/static/250c8266d01e836848b2acf2925b8221/d1af7/WebUI-System_PTZ.avif 460w", "/en/static/250c8266d01e836848b2acf2925b8221/04ac8/WebUI-System_PTZ.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/250c8266d01e836848b2acf2925b8221/a0b58/WebUI-System_PTZ.webp 230w", "/en/static/250c8266d01e836848b2acf2925b8221/bc10c/WebUI-System_PTZ.webp 460w", "/en/static/250c8266d01e836848b2acf2925b8221/d8378/WebUI-System_PTZ.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/250c8266d01e836848b2acf2925b8221/81c8e/WebUI-System_PTZ.png 230w", "/en/static/250c8266d01e836848b2acf2925b8221/08a84/WebUI-System_PTZ.png 460w", "/en/static/250c8266d01e836848b2acf2925b8221/6bbf7/WebUI-System_PTZ.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/250c8266d01e836848b2acf2925b8221/6bbf7/WebUI-System_PTZ.png",
              "alt": "Web User Interface - 720p Series - System PTZ",
              "title": "Web User Interface - 720p Series - System PTZ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PTZTable mdxType="PTZTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Here you can disable the cameras calibration run during start-up (please note that this also deactivates your saved positions!), send it to saved position 1 after a reboot and set the speed and number of auto-scans for the pan & tilt movement when you use the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/PTZ_Control/"
      }}>{`HSCAN or VSCAN`}</a>{`.`}</p>
    <p>{`The Pan & Tilt Limits will be displayed next to the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/PTZ_Control/"
      }}>{`PTZ Control Pad`}</a>{`. You can use them to limit the range of movement of your camera.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      